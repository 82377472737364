import React from "react"
import { Link } from 'gatsby';

import LayoutLPTwo from "../components/layoutLPTwo"
import SEO from "../components/seo"

import "./daas.scss"


export default ({ pageContext }) => (
  <LayoutLPTwo>
    <SEO
      title={pageContext.yoast.title}
      description={pageContext.yoast.metadesc}
      ogimg={pageContext.yoast.opengraph_image.source_url}
      link={pageContext.link}
    />
    <div className={`${pageContext.slug} daas-wrapper`} id="page">
      <div className="lp-hero">
        <div className="container margin-b">
          <div className="row margin-b">
            <div className="col hero-title">
              <h1>
                Desktop As A<br />
                Service Marketing
                <span className="bar"></span>
              </h1>
              <div className="inside-hero text-center">
                <h2 className="inside-hero-h2">
                  Meet with a DaaS expert
                </h2>
                <div className="form-wrapper">
                    <Link href="https://calendly.com/slindars/30min?month=2022-02&back=1" target="_blank">
                        <button type="submit" className="btn btn-white">Schedule Now</button>
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="content-wrapper"
        // dangerouslySetInnerHTML={{ __html: pageContext.content }}
        >
            <div className="container">
              <div className="row margin-b justify-content-center">
                <div className="col-12">
                    <h2>Maximize your marketing and sales potential with the DaaS experts</h2>
                    <p>We are a team of proven DaaS marketers and strategists that have been steeped in the industry since the earliest days of the Microsoft SPLA program and RDS. For over 15 years, we’ve worked across the entire spectrum of DaaS – from technology providers, distributors, and channel partners to industry analysts and global strategic alliances. Our experts have even helped Microsoft and Citrix build their DaaS solutions and channel programs.</p>
                </div>
              </div>
            </div>
            <div className="cta">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3>Here's how our “from tech, for tech” consultative approach to marketing and sales enablement can help you.</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row margin-b">
                    <div className="col-lg-5 offset-lg-1 order-lg-2"><img className="img-fluid" src="https://www.meritmile.com/uploads/2022/02/daas-sample-01.jpg" alt="DaaS - Build your company's brand" /></div>
                    <div className="col-lg-5 order-lg-1">
                        <h3>Develop marketing content </h3>
                        <p>Producing great content takes a team of product experts, researchers, talented writers, and artistic designers. We have them all.</p>
                        <p>Our creative team will engage and educate your prospects with impactful content at every stage of the buying cycle from awareness and consideration through to evaluation and purchasing. From top of funnel content like videos and infographics through to late-stage eBooks, whitepapers, and TCO/ROI tools, we've done it all. </p>
                    </div>
                </div>
                <div className="row margin-b justify-content-center">
                    <div className="col-12">
                        <h2>Grow your sales pipeline</h2>
                        <p>Whether your organization needs a large volume of top of funnel marketing leads or a focused set of highly qualified sales ready leads, we can build and execute a custom demand generation campaign for you. We target your specific audience that’s doing active, in-market research on DaaS and deliver the appropriate level of content for the buying stages you want to focus on.</p>
                    </div>
                </div>
                <div className="row margin-b">
                    <div className="col-lg-6">
                        <img className="img-fluid" src="https://www.meritmile.com/uploads/2022/02/daas-sample-02.jpg" alt="DaaS - Atria Sample" />
                    </div>
                    <div className="col-lg-6">
                        <h4>Take a look at these 2021<br />
                        results we've produced.</h4>
                        <img className="img-fluid" src="https://www.meritmile.com/uploads/2022/02/daas-sample-03.jpg" alt="DaaS - Take a look at these 2021 results we've produced." />
                    </div>
                </div>
            </div>
            <div className="bg-light-blue">
                <div className="container">
                    <div className="row margin-b">
                        <div className="col-lg-6 offset-lg-1 order-lg-2"><img className="img-fluid" src="https://www.meritmile.com/uploads/2022/02/daas-sample-04.png" alt="DaaS - Build your company's brand" /></div>
                        <div className="col-lg-5 order-lg-1">
                        <h3>Build your company's brand</h3>
                        <p>The DaaS market has become increasingly competitive. To stand out in the crowd, you need to ensure that your DaaS offering is well known and differentiated in the market.</p>
                        <p>We'll develop unique and ownable points of view and solution differentiators and land those messages in front of your target audiences. Using a combination of earned media, PR, industry interviews, blogs, and social content, we’ll elevate your brand above the competition.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
              <div className="row margin-b">
                <div className="col-lg-6"><p>&nbsp;</p><img className="img-fluid" src="https://www.meritmile.com/uploads/2022/02/daas-sample-05.jpg" alt="DaaS - Enable your sellers" /></div>
                <div className="col-lg-6">
                    <p>&nbsp;</p>
                  <h3>Enable your sellers</h3>
                  <p>The nature of selling is ever evolving and knowing how to sell-to, sell-with, and sell-through champions, influencers, and decision makers across departments are must-have sales skills.</p>
                  <p>We'll build a custom sales enablement program to help your sellers have the right interactions with buying groups. Merit Mile can create everything your team needs—from training presentations and eLearning to sales playbooks, reference guides, and customer pitches.</p>
                  <p>&nbsp;</p>
                </div>
              </div>
              <div className="row margin-b">
                <div className="col-lg-6 offset-lg-1 order-lg-2"><img className="img-fluid" src="https://www.meritmile.com/uploads/2022/02/daas-takethefirst.jpg" alt="DaaS - Take the first step to better DaaS merkting and sales" /></div>
                <div className="col-lg-5 order-lg-1">
                  <h3>Take the first step to better DaaS marketing and sales</h3>
                  <p>Merit Mile can ensure every part of your marketing and sales organization is prepared to capitalize on the DaaS market opportunity. By working with our team of DaaS experts, your team can do more, market smarter, and sell better.</p>

                  <a className="btn btn-primary" href="https://calendly.com/slindars/30min?month=2022-02&back=1" target="_blank">Start now by meeting with a Merit Mile DaaS strategist.</a>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
            <div className="graybg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"><img className="img-fluid" src="https://www.meritmile.com/uploads/2021/06/mm-headquater.jpg" alt="Merit Mile Office in Boca Raton" /></div>
                  <div className="col-lg-6">
                      <h3>About Merit Mile</h3>
                      <p>Established in 2007 in Boca Raton, FL, Merit Mile is a marketing services agency focused on helping companies and corporate marketing departments build brands, drive sales revenue, and garner publicity in their respective industries. Our unique differentiator at Merit Mile is our ability to 100% own the message, the publicity, and the demand generation aspects of the corporate marketing function. Clients hire us and rehire us for our ability to refine their messaging/value proposition, develop top and mid-funnel leads, and get the type of editorial publicity that differentiates them from their competitors. From branding to PR to demand generation, Merit Mile is the high-octane fuel that invigorates corporate growth engines.</p>
                      <a className="btn btn-primary btn-block" href="https://calendly.com/slindars/30min?month=2022-02&back=1" target="_blank">LEARN MORE</a>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
  </LayoutLPTwo>
)
